export default [
  {
    key: "name",
    label: "Name",
    sortable: true,
  },
  {
    key: "options",
    label: "Options",
    sortable: false,
  },
  {
    key: "created_at",
    label: "Created At",
    sortable: true,
  },
  {
    key: "action",
    thClass: "text-center",
    tdClass: "text-center",
  },
];
