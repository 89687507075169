<template>
  <b-modal
    ref="modalPredefinedAnswersUpdate"
    title="UPDATE PREDEFINED ANSWER"
    modal-class="modal-primary"
    size="sm"
    no-close-on-backdrop
    @hidden="$emit('hidden')"
  >
    <validation-observer ref="form">
      <b-row>
        <b-col cols="12">
          <b-form-group label="Name:">
            <validation-provider
              v-slot="{ errors }"
              rules="required|min:1|max:100"
            >
              <b-form-input
                v-model="name"
                maxlength="100"
                :state="errors.length > 0 ? false : null"
                :disabled="createdBy == 0"
              />
              <span v-if="errors[0]" class="text-danger mt-1"
                >Name {{ errors[0] }}</span
              >
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <div
              class="d-flex justify-content-between align-items-center"
              style="margin-bottom: 2px"
            >
              <label>New Option:</label>
              <feather-icon
                icon="PlusSquareIcon"
                class="cursor-pointer text-success"
                v-b-tooltip.hover.top="'Add Option'"
                size="17"
                @click="addOption"
              />
            </div>
            <b-form-input
              v-model="option"
              placeholder="Enter option"
              @keyup.enter="addOption"
              trim
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Options:">
            <div class="container-options">
              <div
                v-for="(item, index) in options"
                :key="index"
                class="d-flex justify-content-between align-items-center p-1 border rounded mb-1"
              >
                <div>{{ item }}</div>
                <div>
                  <feather-icon
                    icon="TrashIcon"
                    class="cursor-pointer text-danger"
                    size="17"
                    @click="deleteOption(index)"
                  />
                </div>
              </div>
              <div v-if="options.length === 0" class="p-1 border rounded">
                <span>-</span>
              </div>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
    <template #modal-footer>
      <div>
        <b-button variant="primary" @click="updatePredefinedAnswer()">
          SAVE
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import { mapGetters } from "vuex";
import PredefinedAnswersService from "@/views/business/views/settings/service/predefined-answers.service.js";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
export default {
  components: { FeatherIcon },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      name: "",
      options: [],
      createdBy: null,
      option: "",
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  mounted() {
    this.toggleModal("modalPredefinedAnswersUpdate");
    this.getPredefinedAnswerById();
  },
  methods: {
    async getPredefinedAnswerById() {
      try {
        this.addPreloader();
        const { data } = await PredefinedAnswersService.getPredefinedAnswerById(
          this.id
        );
        this.name = data.name;
        this.options = JSON.parse(data.options);
        this.createdBy = data.created_by;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async updatePredefinedAnswer(onlyRefresh = false) {
      try {
        const isValid = await this.$refs.form.validate();
        if (!isValid) return;
        this.addPreloader();
        const params = {
          name: this.name,
          options: JSON.stringify(this.options),
        };
        const { data } = await PredefinedAnswersService.updatePredefinedAnswer(
          this.id,
          params
        );
        const message = onlyRefresh ? "successfully removed" : data.message;
        this.showToast("success", "top-right", `${message}`, "CheckIcon");
        this.$emit("refresh", onlyRefresh);
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    addOption() {
      if (this.option === "") return;
      this.options.push(this.option);
      this.option = "";
    },
    async deleteOption(index) {
      const { isConfirmed } = await this.showConfirmSwal();
      if (!isConfirmed) return;
      this.options.splice(index, 1);
      await this.updatePredefinedAnswer(true);
    },
  },
};
</script>
<style scoped>
.container-options {
  max-height: 300px;
  overflow-y: scroll;
}
</style>