<template>
  <div>
    <filter-slot
      :filter="[]"
      :totalRows="FilterSlot.totalRows"
      :paginate="FilterSlot.paginate"
      :startPage="FilterSlot.startPage"
      :toPage="FilterSlot.toPage"
      :filterPrincipal="FilterSlot.filterPrincipal"
      @reload="$refs['predefinedAnswersTable'].refresh()"
    >
      <b-table
        ref="predefinedAnswersTable"
        slot="table"
        class="blue-scrollbar"
        primary-key="id"
        empty-text="No matching records found"
        responsive="md"
        sticky-header="50vh"
        show-empty
        no-provider-filtering
        :busy.sync="isBusy"
        :fields="Fields"
        :items="myProvider"
        :per-page="FilterSlot.paginate.perPage"
        :current-page="FilterSlot.paginate.currentPage"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>
        <template #cell(options)="data">
          <template v-if="JSON.parse(data.item.options).length > 0">
            <b-badge
              v-for="(option, index) in JSON.parse(data.item.options)"
              :key="index"
              variant="light-primary"
              class="mr-1"
            >
              {{ option }}
            </b-badge>
          </template>
          <span v-else>-</span>
        </template>
        <template #cell(created_at)="data">
          <span>{{ data.item.created_at | myGlobal }}</span>
        </template>
        <template #cell(action)="data">
          <feather-icon
            @click="openModalEdit(data.item.id)"
            icon="EditIcon"
            size="15"
            class="text-success cursor-pointer"
          />
        </template>
      </b-table>
    </filter-slot>
    <modal-predefined-answers-update
      v-if="showModalPredefinedAnswersUpdate"
      :id="answerId"
      @hidden="showModalPredefinedAnswersUpdate = false"
      @refresh="refresh"
    />
  </div>
</template>
<script>
import Fields from "@/views/business/views/settings/data/prededined-answers.fields.js";
import PredefinedAnswersService from "@/views/business/views/settings/service/predefined-answers.service.js";
import ModalPredefinedAnswersUpdate from "@/views/business/views/settings/components/predefined-answers/modals/ModalPredefinedAnswersUpdate.vue";
export default {
  components: {
    ModalPredefinedAnswersUpdate,
  },
  data() {
    return {
      isBusy: false,
      Fields,
      FilterSlot: {
        totalRows: 0,
        paginate: {
          currentPage: 1,
          perPage: 50,
        },
        startPage: 0,
        toPage: 0,
        filterPrincipal: {
          type: "input",
          inputType: "text",
          placeholder: "Name...",
          model: "",
        },
      },
      showModalPredefinedAnswersUpdate: false,
    };
  },
  computed: {},
  mounted() {},
  methods: {
    async myProvider(ctx) {
      try {
        this.addPreloader();

        const orderBy = ctx.sortBy ? ctx.sortBy : "id";
        const order = ctx.sortDesc ? "asc" : "desc";

        const params = {
          program_id: 1,
          page: this.FilterSlot.paginate.currentPage,
          perPage: this.FilterSlot.paginate.perPage,
          orderBy: orderBy,
          order: order,
          search_text: this.FilterSlot.filterPrincipal.model,
        };
        const { data } = await PredefinedAnswersService.getPredefinedAnswers(
          params
        );
        this.FilterSlot.totalRows = data.total;
        this.FilterSlot.startPage = data.from ? data.from : 0;
        this.FilterSlot.toPage = data.to ? data.to : 0;
        this.FilterSlot.paginate.currentPage = data.current_page;
        this.FilterSlot.paginate.perPage = data.per_page;
        return data.data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    openModalEdit(id) {
      this.answerId = id;
      this.showModalPredefinedAnswersUpdate = true;
    },
    refresh(onlyRefresh = false) {
      this.$refs["predefinedAnswersTable"].refresh();
      if (onlyRefresh) return;
      this.showModalPredefinedAnswersUpdate = false;
    },
  },
};
</script>
<style scoped>
</style>